export default (invoiceData, minDaysBeforeReminderFee, vatRates) => ({
  invoice: invoiceData,
  productIndex: 0,
  originalInvoiceState: invoiceData.invoice_state,
  currencyFormatter: new Intl.NumberFormat(window.lang, { style: 'currency', currency: 'EUR' }),
  allowEdits: invoiceData.invoice_state === 'draft',
  vatRates,

  async init() {
    const { invoice } = this

    // Fill in student and product package data if IDs are present in the URL
    // This is done after the initial page load in order to use the frontend logic instead of having to duplicate it on the backend
    const params = new URLSearchParams(window.location.search)
    const studentId = params.get('student_id')
    const packageId = params.get('product_package_id')
    studentId && (await this.selectStudent())
    packageId && (await this.loadProducts(packageId, true))

    // Initialize products from the passed in JSON data
    for (const product of invoice.invoice_products) {
      product.discount = product.discount ? +product.discount : null
      product.price = +product.price
      product.quantity = +product.quantity
      product.initialized = true
      product.index = this.productIndex++
    }

    if (invoice.invoice_products.length === 0 && !packageId) {
      this.addNewProduct()
    }

    invoice.use_interest = !!invoice.penalty_interest_fee
    invoice.penalty_interest_percentage = +invoice.penalty_interest_percentage
    invoice.reminder_fee = +invoice.reminder_fee || 5
    invoice.penalty_interest_fee = +invoice.penalty_interest_fee || null

    for (const payment of invoice.invoice_payments) {
      payment.paid_sum = +payment.paid_sum
    }

    invoice.reference_number ||= this.calculateReference(invoice.invoice_number)
  },

  addNewProduct() {
    this.invoice.invoice_products.push({
      index: this.productIndex++,
      quantity: 1,
      vat_rate_id: this.vatRates.find((rate) => rate.default)?.id,
      initialized: false,
      discount_type: 'none',
    })
  },

  async selectStudent() {
    const { invoice } = this
    if (!invoice.driver_id) return

    try {
      const res = await fetch(`/teacher/students/${invoice.driver_id}/invoice_payer_data`)
      if (!res.ok) throw new Error()

      const { name, business_id, address, zipcode_municipality, phone_number, email } = await res.json()
      invoice.buyer_name = name
      invoice.buyer_business_id = business_id
      invoice.buyer_address = address
      invoice.buyer_zipcode_municipality = zipcode_municipality
      invoice.buyer_phone_number = phone_number
      invoice.buyer_email = email
    } catch {
      alert(window.I18n.unknown_error)
    }
  },

  async selectProducts(originalProduct) {
    const productName = this.$el.value
    const id = productName.split('_')[1]
    const isPackage = productName.startsWith('package')

    // If there's no valid ID, it means that the product wasn't selected from the dropdown, but was typed instead
    if (!parseInt(id)) {
      originalProduct.name = productName
      originalProduct.initialized = true
    } else {
      await this.loadProducts(id, isPackage, originalProduct)
    }
  },

  // Load product data for a product or product package selected from the dropdown
  async loadProducts(id, isPackage, originalProduct = null) {
    try {
      const params = new URLSearchParams({ id, is_package: isPackage, date: this.invoice.date })
      const res = await fetch(`/teacher/invoices/product_rows?${params.toString()}`)
      if (!res.ok) throw new Error()

      const products = await res.json()
      for (const product of products) {
        // Create a new product when adding a product package. For single products, the originalProduct is just edited.
        const newProduct = isPackage || !originalProduct ? { index: this.productIndex++ } : originalProduct
        Object.assign(newProduct, product)
        newProduct.initialized = true

        if (isPackage) {
          this.invoice.invoice_products.push(newProduct)
        }
      }

      if (isPackage && originalProduct) {
        this.removeProduct(originalProduct)
      }
    } catch {
      alert(window.I18n.unknown_error)
    }
  },

  // Get selectable VAT rates from the server when changing the invoice date
  async loadVatRates() {
    try {
      const headers = new Headers()
      headers.set('Content-Type', 'application/json')
      const res = await fetch(`/teacher/invoices/selectable_vat_rates?invoice_date=${this.invoice.date}`, { headers })
      if (!res.ok) throw new Error()

      const rates = await res.json()
      for (const vatRate of rates) {
        vatRate.percentage = +vatRate.percentage
      }

      this.vatRates = rates
    } catch {
      alert(window.I18n.unknown_error)
    }
  },

  removeProduct(product) {
    if (product.id) {
      product._destroy = true
      return
    }

    this.invoice.invoice_products = this.invoice.invoice_products.filter((p) => p !== product)
  },

  calculateReference(invoiceNumber) {
    invoiceNumber = String(invoiceNumber)

    const factors = [7, 3, 1]
    let referenceSum = 0
    let fullReference = ''

    for (let i = 0; i < invoiceNumber.length; i++) {
      const character = invoiceNumber.charAt(invoiceNumber.length - i - 1)
      referenceSum += factors[i % 3] * parseInt(character)

      // Format in groups of 5 for readability
      if ((i + 1) % 5 === 0) {
        fullReference = ' ' + fullReference
      }

      fullReference = character + fullReference
    }

    const lastDigit = referenceSum % 10
    const checksum = (10 - lastDigit) % 10
    return fullReference.concat(checksum)
  },

  async setDefaultPenaltyInterest() {
    this.invoice.use_default_interest ||= !this.invoice.buyer_business_id
    if (!this.invoice.use_default_interest) {
      return
    }

    try {
      const params = new URLSearchParams({ date: this.invoice.date, is_company: !!this.invoice.buyer_business_id })
      const res = await fetch(`/teacher/reference_rates/default_penalty_interest?${params.toString()}`)
      const rate = await res.json()
      this.invoice.penalty_interest_percentage = +rate
    } catch {
      alert(window.I18n.unknown_error)
    }
  },

  roundedEuros(sum) {
    return Math.round(sum * 100) / 100.0
  },

  renderEuros(sum) {
    return this.currencyFormatter.format(sum)
  },

  // Create a "name" attribute for dynamically generated invoice products so that they work when submitting the form without having to convert the data to a format that Rails understands with JS
  productInputName(attributeName) {
    return `invoice[invoice_products_attributes][${this.product.index}][${attributeName}]`
  },

  async changeDate() {
    // The invoice date doesn't update in time for the other functions, so it needs to be updated explicitly
    // This is probably due to the flatpickr firing a 'change' event before the underlying value actually gets changed by x-model
    this.invoice.date = this.$el.value
    await this.setDefaultPenaltyInterest()
    await this.loadVatRates()
  },

  get totalPaid() {
    const paid = this.invoice.invoice_payments.reduce((acc, payment) => acc + payment.paid_sum, 0)
    return this.roundedEuros(paid)
  },

  get totalBeforeDiscount() {
    const rowTotals = this.selectedProducts.reduce((acc, product) => acc + this.rowTotal(product, false), 0)
    const total = rowTotals + this.totalFees
    return this.roundedEuros(this.invoice.prices_incl_vat ? total : total + this.totalVat)
  },

  get totalSum() {
    const total = this.selectedProducts.reduce((acc, product) => acc + this.rowTotal(product), 0)
    return this.roundedEuros(this.invoice.prices_incl_vat ? total : total + this.totalVat)
  },

  get totalFees() {
    return this.roundedEuros(this.reminderFee + this.penaltyInterest)
  },

  get totalWithFees() {
    return this.roundedEuros(this.totalSum + this.totalFees)
  },

  get totalVat() {
    // VAT is calculated from total prices grouped by VAT rate
    const totalsByVatRate = this.selectedProducts.reduce((acc, product) => {
      const vatRate = this.vatRates.find((rate) => rate.id === product.vat_rate_id)
      const percentage = vatRate?.percentage || 0
      acc[percentage] ||= 0
      acc[percentage] += this.rowTotal(product)
      return acc
    }, {})

    // Calculate the amount of included VAT by VAT rate if prices include VAT, or calculate the VAT that needs to be added if they don't
    // Formulas from vero.fi
    const totalVat = Object.entries(totalsByVatRate).reduce((acc, [vatPercentage, total]) => {
      vatPercentage = +vatPercentage
      const includedVat = (vatPercentage * total) / (100 + vatPercentage)
      const addedVat = vatPercentage * (total / 100)
      const totalVat = this.invoice.prices_incl_vat ? includedVat : addedVat

      return this.roundedEuros(acc + totalVat)
    }, 0)

    return this.roundedEuros(totalVat)
  },

  get totalWithoutVat() {
    return this.roundedEuros(this.totalSum - this.totalVat)
  },

  get penaltyInterest() {
    const { use_interest, penalty_interest_fee } = this.invoice
    return use_interest && penalty_interest_fee ? this.roundedEuros(penalty_interest_fee) : 0
  },

  get reminderFee() {
    const { use_reminder, reminder_fee } = this.invoice
    return use_reminder && reminder_fee ? reminder_fee : 0
  },

  get reminderFeesAllowed() {
    const dueDate = new Date(this.invoice.due_date)
    const today = new Date()
    dueDate.setHours(0, 0, 0, 0)
    today.setHours(0, 0, 0, 0)

    const firstAllowedReminderDate = !!this.invoice.buyer_business_id
      ? dueDate.getTime()
      : dueDate.setDate(dueDate.getDate() + minDaysBeforeReminderFee)

    // Reminder fees can be charged from businesses immediately after the due date
    const dateValid = firstAllowedReminderDate < today
    const feeAllowed = dateValid && this.selectedProducts.length > 0

    if (!feeAllowed) {
      this.invoice.use_reminder = false
      this.invoice.use_interest = false
    }

    return feeAllowed
  },

  get selectedProducts() {
    return this.invoice.invoice_products.filter((product) => !product._destroy)
  },

  get showDefaultInterestCheckbox() {
    return this.invoice.buyer_business_id || !this.invoice.use_default_interest
  },

  // Single product methods
  discount(product) {
    switch (product.discount_type) {
      case 'none':
        return 0
      case 'amount':
        return product.discount || 0
      case 'percentage':
        // Both the discount and product total (when using halves) can have fractional cents
        // The exact value of the discount should be used, rounding doesn't happen before calculating the row total
        const discountPercentage = product.discount || 0
        const totalBeforeDiscount = this.unitPrice(product) * this.quantity(product)
        return (totalBeforeDiscount / 100) * discountPercentage
    }
  },

  unitPrice(product) {
    return product.price || 0
  },

  quantity(product) {
    return product.quantity || 0
  },

  rowTotal(product, discounted = true) {
    const total = this.unitPrice(product) * this.quantity(product)
    return this.roundedEuros(discounted ? total - this.discount(product) : total)
  },
})
