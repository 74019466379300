export default (step, selectedPackageId, courseDetails) => ({
  step,
  selectedPackageId,
  courseDetails,
  completedSteps: new Set(),
  hasPreviousCard: null,
  studentAge: null,
  payerAge: null,
  payerSsn: null,
  businessId: null,
  paymentMethod: null,
  hasFinancing: false,
  paytrailData: {},

  init() {
    const inputs = [...this.$refs.form.querySelectorAll('input')]
    inputs.forEach((input) => {
      const events = ['input', 'focusout']
      events.forEach((evt) => {
        input.addEventListener(evt, () => this.validateInput(input))
      })
    })

    // Prevent submitting the form when pressing enter, unless on the last step
    this.$refs.form.addEventListener('keypress', (e) => {
      if (e.key !== 'Enter' || this.step === 4) return

      e.preventDefault()
      this.nextStep()
    })
  },

  nextStep() {
    this.validateCurrentStep() && this.step++
  },

  selectPackage(packageId, packageDetails) {
    this.step = 0
    this.selectedPackageId = packageId
    this.courseDetails = packageDetails
  },

  validateCurrentStep() {
    const currentStep = document.querySelector(`[data-step='${this.step}']`)
    const requiredGroups = currentStep.querySelectorAll('[data-require-one]')
    let invalidElement = currentStep.querySelector(':invalid')

    // Check for required groups (checkboxes / radio buttons) if there are no invalid inputs
    if (requiredGroups.length > 0 && !invalidElement) {
      invalidElement = [...requiredGroups].find((container) => {
        const hasOneChecked = !!container.querySelector('input:checked')
        if (!hasOneChecked) return container
      })
    }

    if (!invalidElement) {
      this.completedSteps.add(this.step)
      return true
    }

    this.removeErrorMsg(invalidElement)
    invalidElement.classList.add('invalid')

    const newErrorInfo = document.createElement('small')
    newErrorInfo.classList.add('text-red-600')

    // Use validationMessage for inputs, otherwise the invalidElement should be a container for checkboxes or radio buttons
    newErrorInfo.innerText = invalidElement.validationMessage || I18n.pick_an_option
    invalidElement.after(newErrorInfo)

    return false
  },

  removeErrorMsg(target) {
    target.closest('[data-step]').querySelector('small.text-red-600')?.remove()
  },

  validateInput(input) {
    if (input.classList.contains('invalid') && input.checkValidity()) {
      input.classList.remove('invalid')
      this.removeErrorMsg(input)
    }
  },

  changeSsn(target) {
    const input = this.$el
    const ssnObject = new Ssn(input.value)
    const age = ssnObject.age
    ssnObject.validateInput(input)

    target === 'student' ? (this.studentAge = age) : (this.payerAge = age)

    if (this.underage && target === 'payer') {
      input.setCustomValidity(this.$refs.payerInfo.innerText)
    }
  },

  submit(resursBankUrl) {
    const form = this.$el
    const submitButton = this.$refs.submitButton

    // Open Resurs bank application in another tab unless the student already has financing
    if (this.paymentMethod === 'resurs_bank' && !this.hasFinancing) {
      window.open(resursBankUrl, '_blank')
    }

    $.post(form.action, $(form).serialize())
      .done((response) => {
        // Move to the payment provider selection step if using Paytrail
        if (this.paymentMethod === 'paytrail') {
          this.paytrailData = response
          this.step++
          return
        }

        form.closest('dialog').remove()
        fetch('/enrolment_success_modal')
          .then((res) => res.text())
          .then((html) => document.body.insertAdjacentHTML('beforeend', html))
      })
      .fail((jqXHR, _status, _error) => alert(jqXHR.responseText))
      .always(() => (submitButton.disabled = false))
  },

  cancelPaytrail() {
    const url = `/enrolls/payment/${this.paytrailData.transactionId}/cancel`
    const data = { checkout_reference: this.paytrailData.checkoutReference }
    $.post(url, data, () => this.step--, 'json')
  },

  setPaymentMethod(paymentMethod) {
    this.paymentMethod = paymentMethod
    this.removeErrorMsg(this.$el)
  },

  get currentIndex() {
    return this.completedSteps.size ? [...this.completedSteps].sort().at(-1) + 1 : 0
  },

  get payerRequired() {
    return this.paymentMethod !== 'paytrail' && this.studentAge < 18
  },

  get underage() {
    const legalAge = 18
    const useBilling = this.paymentMethod === 'billing'

    const adultStudent = this.studentAge >= legalAge
    const adultPayer = this.payerAge >= legalAge
    const useBusinessId = useBilling && this.businessId

    return !adultStudent && !adultPayer && !useBusinessId
  },

  StepButton: {
    [':class']() {
      const index = +this.$el.dataset.index

      const completed = this.completedSteps.has(index)
      const selected = this.step === index
      const current = this.currentIndex === index
      const accessible = selected || completed || current

      const classes = ['flex items-center justify-center p-2 rounded-full']
      accessible ? classes.push('cursor-pointer') : classes.push('bg-neutral-300')
      current && selected && classes.push('bg-primary text-white')
      current && !selected && classes.push('bg-white outline outline-2 outline-primary')
      completed && classes.push('bg-green-500 text-white')
      selected && classes.push('shadow-md')

      return classes.join(' ')
    },
    ['@click']() {
      const index = +this.$el.dataset.index

      // Jumping to a specific step is only allowed if it's the step being currently filled or lower
      const stepAvailable = index <= this.currentIndex

      // Going back from the current step is always allowed, since the current step doesn't have to be complete yet
      // Otherwise, make sure that the user hasn't made any changes that would invalidate the step
      const validOrGoingBackFromCurrent = this.step === this.currentIndex || this.validateCurrentStep()

      if (stepAvailable && validOrGoingBackFromCurrent) {
        this.step = index
      }
    },
  },

  PaymentMethodContainer: {
    [':class']() {
      const baseClasses = 'rounded-lg shadow-md shadow-gray-400 bg-neutral-100 p-4'
      const selected = this.paymentMethod === this.$el.dataset.paymentMethod
      return selected ? `${baseClasses} outline outline-2 outline-primary` : baseClasses
    },
    ['@click']() {
      this.paymentMethod = this.$el.dataset.paymentMethod
      this.removeErrorMsg(this.$el)
    },
  },
})
